import React from "react";
import Home from "../components/Home";


const About = () => {

  return (
    <>
      <Home />
    </>
  );
}

export default About;
